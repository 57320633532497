<template>
  <th-wrapper
    v-loading="dataLoading"
    :title="$t('common.titles.general_info.title')"
    :subtitle="!isNew ? $t('pages.reservations.services.edit.subtitle') : ''"
    class="m-8"
  >
    <el-form ref="form" :model="current" :rules="rules">
      <el-row :gutter="10">
        <el-col :md="12">
          <!-- Name -->
          <el-col>
            <el-form-item prop="name" :label="$t('common.forms.labels.name')">
              <el-input
                id="name"
                v-model="current.name"
                :placeholder="
                  $t('pages.reservations.services.name.placeholder')
                "
              />
            </el-form-item>
          </el-col>
          <!-- Category -->
          <el-col>
            <el-form-item
              prop="product_group"
              :label="$t('pages.reservations.services.category.label')"
            >
              <el-select
                id="product_group"
                v-model="current.product_group"
                class="w-full"
                :placeholder="
                  $t('pages.reservations.services.category.placeholder')
                "
              >
                <el-option
                  v-for="category in resources.serviceCategories"
                  :key="category.product_group_id"
                  :label="category.name"
                  :value="category.product_group_id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- Duration -->
          <el-col :xs="22" :md="16" class="flex">
            <el-form-item
              prop="duration"
              :label="$t('pages.reservations.services.duration.label')"
            >
              <th-number-input
                id="duration"
                v-model="current.duration"
                positive-only
                append-text="mins"
                left-aligned
                :locale="$i18n.locale"
                :lower-limit="5"
                :precision="0"
                :placeholder="
                  $t('pages.reservations.services.duration.placeholder')
                "
              />
            </el-form-item>
          </el-col>
          <!-- Description -->
          <el-col>
            <el-form-item
              prop="description"
              :label="$t('pages.reservations.services.description.label')"
            >
              <el-input
                id="description"
                v-model="current.description"
                type="textarea"
                :rows="3"
                :placeholder="
                  $t('pages.reservations.services.description.placeholder')
                "
              />
            </el-form-item>
          </el-col>
          <!-- Linked product -->
          <el-col>
            <el-form-item
              prop="linked_product"
              :label="$t('pages.reservations.services.linked_product.label')"
            >
              <remote-search-select
                id="linked_product"
                v-model="current.linked_product"
                popper-append-to-body
                resource="products"
                class="w-full"
                fetch-handler="getAll"
                :computed-fields="['custom_id', 'name']"
                :modify-query="modifyQuery"
                :placeholder="
                  $t('pages.reservations.services.linked_product.placeholder')
                "
              />
            </el-form-item>
          </el-col>

          <!-- Available in -->
          <!-- <el-form-item
            for="locations"
            :label="$t('pages.reservations.services.available_in.label')"
          >
            <available-in
              id="locations"
              :model-value="{
                locations: current.locations
              }"
              :resources="resources"
              :show-items-limit="2"
              :hide-branch-groups="true"
              @update:modelValue="onLocationsUpdate"
            />
          </el-form-item> -->
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import pick from 'just-pick'
import servicesModel from '../model/services-model'
import RemoteSearchSelect from '@/components/select/remote-search'
import AvailableIn from '@/components/available-in'

function generateDefault() {
  return {
    name: null,
    product_group: null,
    duration: null,
    description: null,
    linked_product: null,
    locations: null
  }
}

export default {
  name: 'ServicesForm',
  components: {
    RemoteSearchSelect,
    AvailableIn
  },
  setup() {
    const { current, id, save } = servicesModel.setup(generateDefault())
    return { current, id, save }
  },
  data() {
    return {
      resources: {
        branches: [],
        serviceCategories: []
      },
      dataLoading: true,
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pages.reservations.services.name.required')
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        duration: [
          {
            required: true,
            message: this.$t('pages.reservations.services.duration.required')
          },
          {
            validator: this.validateDuration,
            trigger: 'blur'
          }
        ],
        description: [
          {
            max: 4096,
            message: this.$t('common.forms.rules.max_length', { length: 4096 })
          }
        ],
        linked_product: [
          {
            required: true,
            message: this.$t(
              'pages.reservations.services.linked_product.required'
            ),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    isNew() {
      return !this.$route.params.id
    }
  },
  async created() {
    this.id = this.$route.params.id
  },
  async mounted() {
    this.dataLoading = true

    await this.fetchResources()

    if (!this.isNew) {
      await this.fetch()
    }

    this.dataLoading = false
  },
  methods: {
    onLocationsUpdate(event) {
      this.current.locations = event.locations
    },
    async fetchResources() {
      try {
        const { branchesV1, serviceCategories } = await this.$resourceFetch(
          {
            resource: 'branchesV1',
            query: {
              deleted: false
            }
          },
          {
            prop: 'serviceCategories',
            resource: 'product_groups',
            query: { is_service_category: true, deleted: false }
          }
        )

        this.resources = {
          branches: branchesV1,
          serviceCategories: serviceCategories.sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
        }
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    async fetch() {
      try {
        const inst = th.products()

        const { data = {} } = await inst.get(this.id)

        if (data.id) {
          this.transformServiceData(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.reservations.services.singular')
          })
        })
      }
    },
    transformServiceData(service) {
      const cleanedPayload = pick(service, Object.keys(this.current))
      this.current = {
        ...this.current,
        ...cleanedPayload,
        linked_product: service.linked_product_id
      }
    },
    modifyQuery(q) {
      return {
        query: {
          limit: 50,
          deleted: false,
          exclude_system_products: true,
          type: [
            'product',
            'composed_product',
            'voucher',
            'linked',
            'linked_product',
            'variant',
            'variant_product'
          ],
          cursor_field: '+name',
          q: q || undefined
        }
      }
    },
    async submitForm() {
      const resource = this.$t('common.resource.reservations.services.singular')

      const errorMessage = this.isNew
        ? this.$t('common.error.action.create.single', { resource })
        : this.$t('common.error.action.update.single', { resource })

      const successMessage = this.isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })

      const ampliEvent = this.isNew ? 'serviceCreated' : 'serviceUpdated'

      const formValid = await this.$refs.form.validate()

      if (!formValid) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'common.forms.rules.field_warnings.invalid_inputs.required'
          )
        })
      }

      const { error } = await this.save()

      if (!error) {
        this.$ampli.eventWithBaseProps(ampliEvent)
      }

      //handle errors
      if (error) {
        this.$logException(error, {
          message: errorMessage
        })
        //handle success
      } else if (this.id) {
        this.$message({
          type: 'success',
          message: successMessage
        })

        if (this.navigationAfterCreation === 'edit') {
          this.$router.push({
            name: 'services-edit',
            params: { id: this.id }
          })
        } else {
          this.$router.push({ name: 'services' })
        }
      }
    },
    validateDuration(rule, value, callback) {
      if (isNaN(this.current.duration)) {
        this.current.duration = null
        callback(
          new Error(this.$t('pages.reservations.services.duration.required'))
        )
      }
      if (this.current.duration > 1440) {
        callback(
          new Error(this.$t('pages.reservations.services.duration.more_than'))
        )
      } else {
        callback()
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(this.current.name || this.id)
      if (confirm) {
        await this.deleteService(this.id)
      }
    },
    async deleteService(id) {
      const resource = this.$t('common.resource.reservations.services.singular')
      const successMessage = this.$t('common.success.action.delete.single', {
        resource
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource
      })

      try {
        const inst = th.products()
        await inst.delete(id)
        this.$ampli.eventWithBaseProps('serviceDeleted')
        this.$message({
          type: 'success',
          message: successMessage
        })

        //handle success
        this.$router.push({ name: 'services' })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    }
  }
}
</script>
